<template>
  <div class="Dialogue">
    <div class="top-title actives">
      <div class="top-text">会话列表</div>

    </div>

    <div class="table-tap">
        <ul>
          <li> <span class="active"> 商家</span></li>
          <li> <span>会员</span> </li>
        </ul>
    </div>
    <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list" row-class-name="tableRowStyle" v-loading="loading">
        <el-table-column label="序号" width="70px">
           <template slot-scope="scope"> {{scope.$index+1}} </template>
          </el-table-column>
        <el-table-column prop="membership" label="姓名"> </el-table-column>
        <el-table-column prop="content" label="内容"> </el-table-column>

        <el-table-column label="操作" width="300" class="table-form-btn">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              class="table-form-btn"  type="text"
              >聊一聊</el-button>
            <el-button
              @click="handleClickRemove(scope.row)"
              class="table-form-btn"  type="text"
              >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="block" style="margin-top: 15px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
            // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      // 分页
      currentPage: 1, // 当前页码
      total: 10,
      pageSize: 10, // 每页的数据条数
      loading: this.$common.loading,

      // 下拉框
      seeks: {},
      seeklist:fakelist.seeklist,

      list:fakelist.liste,
    };
  },
  created() {
    this.getSong()
  },
  mounted() {
    			//选项卡
			$(function() {
				$(".table-tap ul li span").click(function() {
					$(".table-tap ul li span").removeClass("active");
					$(this).addClass("active");
				});
			});
  },

  methods: {
     async getSong(){
				//查询接口
				let res = await apiurl.deptlist({
        // pageNum: this.currentPage,
        // pageSize: this.pageSize,
        })
            if(typeof(res.ErrMsg)=="undefined"){
                    alert("接口调用失败");
                }else if(res.ErrMsg){
                    alert("接口调用失败:"+res.ErrMsg);                    
                }else{
                    // console.log("成功："+JSON.stringify(res.Result));
                     this.list = fakelist.Dialogue.rows
                     this.total = fakelist.Dialogue.total
                     this.loading = false
                    // console.log(this.list);
                }
      },
    // 聊一聊
    handleClick(row) {
    
    },
    // 下架或者删除
    handleClickRemove(row) {
     console.log("删除");
    },

    // 搜索栏
    seek(){
        console.log(this.seeks);
    },



    // 分页 每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSong();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSong();
    },
  },
  computed: {},
};
</script>


<style  scoped>
.Complaint .top-title{
  height: 70px;
}
.table-tap{
  width: 98%;
  margin: 0 auto;
  padding: 30px 0;
  border-bottom: 2px solid rgb(255, 187, 0);
}
.table-tap ul{
  display: flex;
  justify-content: left;
}
.table-tap ul li{
  width: 150px;
  text-align: center;
}
.table-tap ul li span{
font-size: 18px;
cursor: pointer;
transition: all .3s;
}
.table-tap ul li span.active{
  color: rgb(255, 187, 0);
  font-size: 16px;
}
@import "../../../assets/css/liststyle.css";
</style>